import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.nav');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-nav',
template: templateSource || '',
    styles: [`

/* file: /opt/testsp/project/main/build/src/app/component.nav/view.scss */
.navbar {
  padding: 20px 0;
  background: var(--main-bg);
  box-shadow: none;
}
.navbar .container {
  justify-content: initial;
  padding: 0;
}
.navbar .navbar-brand img {
  height: 32px;
}
.navbar .navbar-brand strong {
  font-family: main-eb;
  font-size: 30px;
}
.navbar .navbar-brand .btn-group .badge {
  color: black;
}`],
})
export class ComponentNavComponent implements OnInit {

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
    }

    public goMain() {
        this.service.href("/landing");
    }
}

export default ComponentNavComponent;