import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.oidc.register');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-oidc-register',
template: templateSource || '',
    styles: [`

/* file: /opt/testsp/project/main/build/src/app/portal.oidc.register/view.scss */
.text-red {
  color: red !important;
}

.information {
  position: relative;
  height: 330px;
}
.information .title {
  font-size: 3rem;
  font-family: main-eb;
}
.information .subtitle {
  font-size: 2rem;
}

.content {
  background: white;
  border-radius: 10px;
}
.content .form-group + .form-group {
  margin-top: 15px;
}
.content table tr {
  max-height: 40px;
}
.content table tr th {
  width: 200px;
  background: #f6f6f6;
  align-content: center;
}
.content table tr td {
  padding: 8px;
}

.form-check {
  cursor: pointer;
}

.btn-registration {
  background-color: rgba(0, 0, 139, 0.75);
}
.btn-retrieve {
  background-color: #4263EB;
}

.bg-disabled {
  background: #F2F5F9;
}

.form-check-input:checked {
  background-color: #4263EB;
}`],
})
export class PortalOidcRegisterComponent implements OnInit {
    public O = Object;

    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
    }
    public mode = ["Retrieving from provider", "Manual configuration"];
    public selectedMode = "Retrieving from provider"
    public endpointInfo = false;
    // public getEndPoint() { this.endpointInfo = true; };
    public changeMode() {
        if (this.selectedMode == "Retrieving from provider") {
            this.endpointInfo = false;
            this.data.endpoints = this.emptyEndpoints();
        }
    }

    public emptyEndpoints() {
        return [
            ["issuer", ""],
            ["authorization_endpoint", ""],
            ["token_endpoint", ""],
            ["userinfo_endpoint", ""],
            ["end_session_endpoint", ""],
            ["jwks_uri", ""],
        ];
    }
    public isDefault(key) {
        return ["issuer", "authorization_endpoint", "token_endpoint", "userinfo_endpoint", "end_session_endpoint"].includes(key);
    }
    public data = {
        issuer: "",
        client_id: "",
        client_secret: "",
        endpoints: this.emptyEndpoints(),
    };

    public validation() {
        if (this.data.issuer.replace(/\s/g, "").length < 11) return false;
        if (this.data.client_id.replace(/\s/g, "").length < 1) return false;
        if (this.data.client_secret.replace(/\s/g, "").length < 1) return false;
        return true;
    }

    public async registration() {
        if (!this.validation()) return await this.service.error("Check a required value.");
        const body = JSON.parse(JSON.stringify(this.data));
        if (this.configuration.issuer) body.issuer = this.configuration.issuer;
        body.endpoints = body.endpoints.reduce((acc, [key, val]) => {
            if (val.replace(/\s/g, "").length === 0) return acc;
            acc[key] = val;
            return acc;
        }, {});
        await this.service.loading.show();
        const { code, data } = await wiz.call("registration", body);
        await this.service.loading.hide();
        if (code === 400) {
            return await this.service.error(data);
        }
        if (code === 403) {
            const res = await this.service.alert.show({
                title: "Already registered",
                message: data,
                action: "overwrite",
                cancel: "close",
            });
            if (res) {
                await this.service.loading.show();
                await wiz.call("unregistration", body);
                await this.service.loading.hide();
                await this.registration(body);
            }
            return;
        }
        if (code !== 200) return this.service.error("Faild to registration. Check a required value.");
        await this.service.success("Success to registration. Go to authenticate page.");
        this.service.href("/oidc/authenticate");
    }

    public redirectURI() {
        return `${location.origin}/api/oidc/callback`;
    }

    public configuration = null;
    public text = "";
    public validConfigURL() {
        if (!this.data.issuer) return false;
        if (!this.data.issuer.startsWith("http")) return false;
        return true;
    }
    public async getConfiguration() {
        this.text = "";
        if (!this.validConfigURL()) {
            return;
        }
        let { issuer } = this.data;
        if (issuer.endsWith("/")) issuer = issuer.slice(0, -1);

        const { code, data } = await wiz.call("configuration", { issuer });
        if (code !== 200) {
            this.configuration = false;
            return;
        }
        this.configuration = data;
        if (!data) this.configuration = false;
        if (this.configuration) {
            await this.service.success("success to call .well-known/openid-configuration!");
            this.data.endpoints = Object.entries(this.configuration).reduce((acc, [key, value]) => {
                // if (!key.endsWith("_endpoint") && !["jwks_uri", "issuer"].includes(key)) return acc;
                const emptyEndpoints = this.emptyEndpoints();
                const validKeys = emptyEndpoints.map(item => item[0]);
                if (!validKeys.includes(key)) return acc;
                const idx = acc.findIndex((item) => item[0] === key);
                if (idx < 0) acc.push([key, value]);
                else acc[idx][1] = value;
                return acc;
            }, this.emptyEndpoints());
        }
        this.endpointInfo = true;
        await this.service.render();
    }
}

export default PortalOidcRegisterComponent;