import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.oidc.authenticate');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-oidc-authenticate',
template: templateSource || '',
    styles: [`

/* file: /opt/testsp/project/main/build/src/app/portal.oidc.authenticate/view.scss */
.h2 {
  font-weight: normal;
}

.row select.form-select.text-muted {
  color: rgba(var(--tblr-muted-rgb), 0.6) !important;
}

pre {
  white-space: pre-wrap;
  word-break: break-all;
}

.content-wrap .row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.content-wrap .btn-logout {
  width: auto;
  padding: 10px 50px;
}
.content-wrap .bt {
  border-top: 2px solid black;
}
.content-wrap .bb {
  border-bottom: 1px solid darkgray;
}
.content-wrap .copy-msg {
  top: 10px;
  right: 10px;
}

.br-10 {
  border-radius: 10px;
}

.btn-registration {
  background-color: rgba(0, 0, 139, 0.75);
}

.table thead tr th {
  font-weight: bold;
  font-size: 15px;
  color: white;
  background-color: #C0C0C0;
  text-transform: none;
}
.table tbody th {
  font-weight: normal;
}

.form-label {
  font-size: medium;
}

.form-check-input:checked {
  background-color: #4263EB;
}

.container-pkce {
  border: 1px solid #f6f6f6;
}

.table-custom tr {
  max-height: 40px;
}
.table-custom tr th {
  width: 200px;
  background: #f6f6f6;
  align-content: center;
  font-weight: bold;
}
.table-custom tr td {
  padding: 8px;
}`],
})
export class PortalOidcAuthenticateComponent implements OnInit {
    public session = {};
    public O = Object;
    public clients = [];
    public client = null;

    constructor(@Inject( Service)         public service: Service,    ) { }

    public error = null;

    public async ngOnInit() {
        await this.service.init();
        if (location.hash.length > 1) {
            const params = new URLSearchParams("?" + location.hash.slice(1));
            const data = {};
            params.forEach((val, key) => {
                data[key] = val;
            });
            window.history.replaceState(null, '', "/oidc/authenticate");
            if (data.error) {
                this.error = error;
                return;
            }
            await wiz.call("token_login", data);
            await this.service.auth.init();
        }
        this.session = this.service.auth.session;
        if (!this.session.state)
            await this.load();
        const { codeVerifier, codeChallenge } = await this.generatePkceCodes();
        this.codeVerifier = codeVerifier;
        this.data.code_challenge = codeChallenge;
        setTimeout(async () => {
            while (true) {
                await this.getLoginUri();
            }
        }, 0);
        await this.service.render();
    }

    public async load() {
        const { code, data } = await wiz.call("clients");
        if (code !== 200) return;
        this.clients = data;
        await this.service.render();
    }

    public loginUri = "";
    public loginPostData = "";
    public data = {
        acr_values: "",
        scope: "openid profile",
        claims: "",
        response_type: "code",
        response_mode: "form_post",
        pkce: false,
        code_challenge_method: "S256",
        code_challenge: undefined,
        nonce: Math.random().toString(26).slice(2),
        grant_type: "authorization_code", // authorization_code, client_credentials
    };
    public state = "";
    public codeVerifier = "";

    public responseType = {
        code: true,
        token: false,
        id_token: false,
    };
    public flowType() {
        const t = this.responseType;
        if (t.code && !t.token && !t.id_token) return "authorization_code";
        if (!t.code && !t.token && !t.id_token) return "none";
        if ((t.code && t.token) || (t.code && t.id_token)) return "hybrid";
        return "implicit";
    }
    public onChangeResType() {
        let type = [];
        if (this.responseType.code) type.push("code");
        if (this.responseType.token) type.push("token");
        if (this.responseType.id_token) type.push("id_token");
        this.data.response_type = type.sort().join(" ");
        if (this.data.response_type === "") this.data.grant_type = "client_credentials";
        else this.data.grant_type = "authorization_code";
        this.service.render();
    }

    public onChangeGrantType() {
        if (this.data.grant_type === "client_credentials") {
            this.responseType.code = false;
            this.responseType.token = false;
            this.responseType.id_token = false;
            this.onChangeResType();
        }
    }

    public claims = {
        idtoken: false,
        userinfo: false,
        text: "",
    };
    public onChangeClaims() {
        this.data.claims = "";
        let obj = {};
        try {
            obj = this.claims.text.trim().split(/\s+/).reduce((acc, val) => {
                acc[val] = { essential: true };
                return acc;
            }, {});
        } catch {
            return;
        }
        const claims = {};
        if (this.claims.idtoken) claims.id_token = obj;
        if (this.claims.userinfo) claims.userinfo = obj;
        if (Object.keys(claims).length > 0) this.data.claims = claims;
        this.service.render();
    }

    public async onChangePKCE() {
        if (this.data.code_challenge_method === "plain") {
            this.data.code_challenge = this.codeVerifier;
        }
        else {
            this.data.code_challenge = await this.generateCodeChallenge(this.codeVerifier);
        }
        await this.service.render();
    }

    public tmp = null;
    public getBody() {
        const body = JSON.parse(JSON.stringify(this.data));
        const del = () => {
            delete body.code_challenge_method;
            delete body.code_challenge;
        }
        if (body.response_type !== "code") del();
        else if (!body.pkce) del();
        delete body.pkce;
        body.issuer = this.client;

        if (body.grant_type === "client_credentials") {
            delete body.response_type;
            delete body.response_mode;
        }

        return body;
    }
    public async getLoginUri() {
        if (!this.client) {
            await this.service.render(1000);
            return;
        }
        const body = this.getBody();
        const tt = JSON.stringify(body);
        if (this.tmp === tt) {
            await this.service.render(1000);
            return;
        }
        this.tmp = tt;

        body.useUserinfo = this.useUserinfo;
        this.loginUri = "";
        this.loginPostData = "";
        const { code, data } = await wiz.call("login", body);
        if (code !== 200) {
            console.log("error!", data);
            return;
        }
        if (body.grant_type === "authorization_code") {
            this.loginUri = data;
        }
        else if (body.grant_type === "client_credentials") {
            this.loginPostData = JSON.stringify(data);
        }
        await this.service.render();
    }
    public async login() {
        if (this.loginUri.length > 0) {
            const tt = JSON.stringify(this.getBody());
            if (this.tmp !== tt)
                await this.getLoginUri();
            location.href = this.loginUri;
            return;
        }
        else if (this.loginPostData.length > 0) {
            await this.service.loading.show();
            const body = this.getBody();
            body.useUserinfo = this.useUserinfo;
            await wiz.call("login_post", body);
            await this.service.loading.hide();
            await this.service.auth.init();
            this.session = this.service.auth.session;
            return await this.service.render();
        }
    }

    public prettyUri(uri) {
        const origin = uri.split("?")[0];
        const params = new URLSearchParams("?" + uri.split("?").slice(1).join("?"));
        const arr = [];
        params.forEach((val, key) => {
            arr.push([key, val]);
            if (key === "state" && this.state !== val) {
                this.state = val;
                // this.service.render();
            }
        });
        return `${origin}\n\t?${arr.map(it => `${it[0]}=${it[1]}`).join("\n\t&")}`;
    }

    public logoutOP() {
        location.href = "/api/oidc/logout";
    }

    public async logoutLocal() {
        await wiz.call("logout_local");
        location.reload();
    }

    public isJson(text) {
        const txt = "" + text;
        if (txt.startsWith("{") && txt.endsWith("}")) return true;
        return false;
    }

    public pretty(text) {
        let t = text;
        try {
            if (t !== null && t.constructor.name === "Object") // isObject
                return JSON.stringify(t, null, 2);
            else
                return JSON.stringify(JSON.parse(t), null, 2);
        } catch {
            return t;
        }
    }

    public parseJWT(text) {
        try {
            let res = text.split('.')[1];
            res = res.replace(/-/g, '+').replace(/_/g, '/');
            res = atob(res);
            res = JSON.parse(res);
            if (res.client_id) res.client_id = "__hidden__";
            res = JSON.stringify(res, null, 2);
            return res;
        } catch {
            return "Parsing error!";
        }
    }

    // Generate a random string for code_verifier
    generateCodeVerifier(): string {
        const array = new Uint32Array(32);
        window.crypto.getRandomValues(array);
        return Array.from(array, dec => ('0' + dec.toString(16)).substr(-2)).join('');
    }

    // Create SHA-256 hash of the code_verifier
    async generateCodeChallenge(codeVerifier: string): Promise<string> {
        const encoder = new TextEncoder();
        const data = encoder.encode(codeVerifier);
        const hashBuffer = await window.crypto.subtle.digest('SHA-256', data);
        return this.base64UrlEncode(hashBuffer);
    }

    // URL-safe Base64 encode the hash
    private base64UrlEncode(buffer: ArrayBuffer): string {
        const bytes = new Uint8Array(buffer);
        let binary = '';
        for (let i = 0; i < bytes.byteLength; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        const base64 = window.btoa(binary);
        return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
    }

    // Example function to generate PKCE codes
    async generatePkceCodes(): Promise<{ codeVerifier: string, codeChallenge: string }> {
        const codeVerifier = this.generateCodeVerifier();
        const codeChallenge = await this.generateCodeChallenge(codeVerifier);
        return { codeVerifier, codeChallenge };
    }

    public isshowDelete() {
        if (this.session.id_token) return true;
        if (this.session.access_token && this.session.userinfo) return true;
        return false;
    }

    public async deleteClient() {
        const res = await this.service.alert.show({
            title: "Delete Client",
            message: "Are you sure you want to delete this client?",
            action: "delete",
            cancel: "cancel",
        });
        if (!res) return;
        const { code } = await wiz.call("delete_client");
        if (code !== 200) return this.service.error("Failed to delete client.");
        await this.service.success("Success to delete client.");
        this.logoutLocal();
    }
}

export default PortalOidcAuthenticateComponent;