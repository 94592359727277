import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.saml.register');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-saml-register',
template: templateSource || '',
    styles: [`

/* file: /opt/testsp/project/main/build/src/app/portal.saml.register/view.scss */
.text-red {
  color: red !important;
}

.information {
  position: relative;
  height: 330px;
}
.information::before {
  content: "";
  width: 500px;
  background: url(/assets/images/background.png) center/contain no-repeat;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -140px;
  opacity: 0.3;
}
.information .title {
  font-size: 3rem;
  font-family: main-eb;
}
.information .subtitle {
  font-size: 2rem;
}
.information .info-text {
  font-family: main-b;
}
.information .info-text .text-red {
  font-family: main-eb;
}

.input-area {
  z-index: 10;
  width: 400px;
  background-color: rgba(255, 255, 255, 0.9);
}
.input-area .mode {
  cursor: pointer;
  text-align: center;
  background-color: #ededed;
  padding: 15px 0;
  font-family: main-b;
}
.input-area .mode.active {
  background-color: inherit;
}
.input-area .input-content {
  min-height: 280px;
}
.input-area .file-custom {
  cursor: pointer;
  height: 50px;
  line-height: 50px;
  border: 1px solid #dddddd;
}
.input-area .file-custom strong {
  line-height: 32px;
  border-left: 1px solid #dddddd;
}
.input-area .btn {
  height: 44px;
}
.input-area textarea {
  min-height: 172px;
  min-width: 100%;
  resize: both;
}`],
})
export class PortalSamlRegisterComponent implements OnInit {

    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
    }

    public mode = "file";
    public changeMode(mode) {
        this.mode = mode;
        this.file = null;
        this.text = "";
        this.service.render();
    }

    public file = null;

    public isValidXML(text) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(text, "application/xml");
        const err = doc.querySelector("parsererror");
        if (err) return false;
        return true;
    }

    public async onFileSelect(e) {
        const files = e.target.files;
        if (files.length === 0 || !files) {
            this.file = null;
            return;
        }
        const text = await files[0].text();
        if (!this.isValidXML(text)) {
            await this.service.error("XML 형식의 파일이 아닙니다.");
            this.file = null;
            await this.service.render();
            return;
        }
        this.file = files[0];
        this.service.render();
    }

    public async upload() {
        if (!this.file) return;

        const body = new FormData();
        body.append("file[]", this.file);

        await this.service.loading.show();
        const { code } = await wiz.call("upload", {}, { body, headers: undefined });
        await this.service.loading.hide();
        if (code !== 200) return await this.service.error("Invalid metadata format");
        this.next();
    }

    public text = "";

    public async enroll() {
        if (!this.isValidXML(this.text)) return await this.service.error("XML 형식의 파일이 아닙니다.");

        const body = {
            text: this.text,
        };
        await this.service.loading.show();
        const { code } = await wiz.call("enroll", body);
        await this.service.loading.hide();
        if (code !== 200) return await this.service.error("Invalid metadata format");
        this.next();
    }

    private next() {
        this.service.href("/saml/metacheck");
    }
}

export default PortalSamlRegisterComponent;