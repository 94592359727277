import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.saml');
import { OnInit } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-saml',
template: templateSource || '',
    styles: [``],
})
export class PageSamlComponent implements OnInit, DoCheck {
    public tab = "register";

    constructor(@Inject( Service)         public service: Service,    ) {
        const segment = WizRoute.segment;
        if (!segment[0]) return service.href("/saml/register");
        const arr = segment[0].split("/");
        this.tab = arr[0];
    }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow();
    }

    public async ngDoCheck() {
        const segment = WizRoute.segment;
        if (!segment[0]) return this.service.href("/saml/register");
        const arr = segment[0].split("/");
        this.tab = arr[0];
    }
}

export default PageSamlComponent;