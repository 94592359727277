import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('layout.topnav');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-layout-topnav',
template: templateSource || '',
    styles: [`

/* file: /opt/testsp/project/main/build/src/app/layout.topnav/view.scss */
.wiz-page {
  flex-direction: column;
  background: var(--main-bg);
}`],
})
export class LayoutTopnavComponent implements OnInit {
    constructor(@Inject( Service    )         public service: Service    ) { }

    public async ngOnInit() {
        await this.service.init();
    }
}

export default LayoutTopnavComponent;