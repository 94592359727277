import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.pre');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-pre',
template: templateSource || '',
    styles: [`

/* file: /opt/testsp/project/main/build/src/app/component.pre/view.scss */
pre {
  white-space: pre-wrap;
  word-break: break-all;
}

.position-relative:hover .btn {
  display: block;
}

.btn {
  display: none;
  top: 10px;
  right: 10px;
}

.copy-msg {
  top: 10px;
  right: 40px;
}`],
})
export class ComponentPreComponent implements OnInit {
    @Input() text: any;
    @Input() TF: any = false;
    @Input() isURI: boolean = false;

    constructor(@Inject( Service)         public service: Service,    ) { }
    public async ngOnInit() {
        await this.service.init();
    }

    public copied = null;
    public copy(value) {
        let text = value;
        if (typeof value !== "string") text = JSON.stringify(value);

        if (this.isURI) {
            text = text.replace(/[\n\t]/g, "");
            function encodeUriParameters(uri) {
                // URL과 파라미터 분리
                const [baseUrl, queryString] = uri.split("?");
                if (!queryString) return uri; // 파라미터가 없으면 그대로 반환

                // 파라미터를 키-값 형태로 파싱
                const params = new URLSearchParams(queryString);
                const encodedParams = new URLSearchParams();

                // 각 값에 대해 URI 인코딩 수행
                for (const [key, value] of params.entries()) {
                    // encodedParams.append(key, encodeURIComponent(value));
                    encodedParams.append(key, value);
                }

                // 다시 조합
                return `${baseUrl}?${encodedParams.toString()}`;
            }
            text = encodeUriParameters(text);
        }

        if (navigator.clipboard) {
            navigator.clipboard.writeText(text);
        }
        else {
            const textArea = document.createElement('textarea');
            textArea.style.opacity = 0;
            textArea.value = text;
            document.body.appendChild(textArea);
            textArea.select();
            textArea.setSelectionRange(0, 99999);
            try {
                document.execCommand('copy');
            } catch (err) {
                console.error('복사 실패', err);
            }
            textArea.setSelectionRange(0, 0);
            document.body.removeChild(textArea);
        }
        if (this.copied) {
            clearTimeout(this.copied);
            this.copied = null;
        }
        this.copied = setTimeout(() => {
            this.copied = null;
            this.service.render();
        }, 1500);
        this.service.render();
    }
}

export default ComponentPreComponent;