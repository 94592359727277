import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('layout.empty');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-layout-empty',
template: templateSource || '',
    styles: [`

/* file: /opt/testsp/project/main/build/src/app/layout.empty/view.scss */
.wiz-page {
  background: var(--main-bg);
}`],
})
export class LayoutEmptyComponent implements OnInit {
    constructor(@Inject( Service    )         public service: Service    ) { }

    public async ngOnInit() {
        await this.service.init();
    }
}

export default LayoutEmptyComponent;