import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.season.loading');
import { OnInit } from '@angular/core';

@Component({
    selector: 'wiz-portal-season-loading',
template: templateSource || '',
    styles: [`

/* file: /opt/testsp/project/main/build/src/app/portal.season.loading/view.scss */
.season-loading-default {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999999;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
}
.season-loading-default svg path,
.season-loading-default svg rect {
  fill: #FF6700;
}`],
})
export class PortalSeasonLoadingComponent implements OnInit {
    constructor() { }

    public async ngOnInit() {
    }
}

export default PortalSeasonLoadingComponent;